import {
  Component,
  OnInit
} from '@angular/core';
import {
  UtilsService
} from '../_services/utils.service';
import {
  Router
} from '@angular/router';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import {
  BaseUrlService
} from '../_services/base-url.service';


@Component({
  selector: 'app-file-search',
  templateUrl: './file-search.component.html',
  styleUrls: ['./file-search.component.scss']
})
export class FileSearchComponent implements OnInit {
  utilService: any;
  rowData: any = [];
  token: any;
  searchTable: any = [];
  dataForExcel = [];

  gridOptions: any = {
    enableBrowserTooltips: true,
    columnDefs: [{
        headerName: 'NAME',
        field: 'fileName',
        sortable: true,
        filter: true,
        tooltipField: 'fileName',
        minWidth: 280
      },
      {
        headerName: 'NUMBER',
        field: 'fileNumber',
        sortable: true,
        filter: true,
        tooltipField: 'fileNumber',
        maxWidth: 105
      },
      {
        headerName: 'LENGTH',
        field: 'fileLength',
        sortable: true,
        filter: true,
        tooltipField: 'fileLength',
        type: 'rightAligned',
        maxWidth: 100
      },
      {
        headerName: 'DESCRIPTION',
        field: 'description',
        sortable: true,
        filter: true,
        tooltipField: 'description',
        minWidth: 1000
      }
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true
    },
    rowSelection: 'single',
    onSelectionChanged: () => {
      const env = this.getEnvironment();
      if ( env === 'QA' || env === 'LOCAL' ) {
      this.router.navigate(['NA/file-search-details-tree']).then(
        (resp) => {
          console.log('navigated');
        }
      );
      } else if (env === 'CAT' || env === 'PROD' ) {
        this.router.navigate(['NA/file-search-details']).then(
          (resp) => {
            console.log('navigated');
          }
        );
        }
    },
    onRowClicked(event: any) {
      sessionStorage.setItem('fileNumber', event.data.fileNumber);
      sessionStorage.setItem('description', event.data.description);
      sessionStorage.setItem('fileName', event.data.fileName);
    },
    onGridReady: (params) => {
      this.fetchRowData();
      params.api.setRowData(this.rowData);
    },
    overlayNoRowsTemplate: '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="sr-only">Loading...</span></div>',
    overlayLoadingTemplate: '<span>No Records to Show</span>',

  };

  constructor(utilService: UtilsService, private router: Router, private http: HttpClient, private baseUrlService: BaseUrlService) {
    this.utilService = utilService;
  }

  ngOnInit(): void {
    this.utilService.updateMetaValues();
  }

  fetchRowData() {
    this.token = this.baseUrlService.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    const body = {};
    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/findBatchFiles',
        body, httpOptions
      )
      .subscribe(
        data => {
          this.searchTable = data;
          this.searchTable = this.searchTable.results;
          if (this.gridOptions.api && this.searchTable === undefined) {
            this.gridOptions.api.showLoadingOverlay();
          }
          else if (this.searchTable !== undefined) {
          this.searchTable.sort((a, b) => (a.fileName > b.fileName) ? 1 : -1)
          if (this.searchTable !== undefined) {
            for (const item of this.searchTable ) {
              this.rowData.push(item);
            }
            this.renderInChunks();
          }
        }
        },
        error => {
          if (error.status != 200) {
            this.gridOptions.api.showLoadingOverlay();
        }
      }
      )
  }

  onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
  }

  resetAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    (document.getElementById('filter-text-box') as HTMLInputElement).value = '';
    this.onFilterTextBoxChanged();
  }

  renderInChunks() {
    const DEFAULT_CHUNK_SIZE = 100;
    const dataLength = this.rowData.length;
    let results = [].concat(this.rowData)
    const nextRender = (howMany) => {
      var chunkSize = howMany || DEFAULT_CHUNK_SIZE;
      this.gridOptions.api.applyTransaction({
        add: results.slice(0, howMany)
      });
      results.splice(0, chunkSize);
      if (results.length) {
        const howMany = results.length > DEFAULT_CHUNK_SIZE ? DEFAULT_CHUNK_SIZE : results.length
        setTimeout(() => {
          nextRender(howMany)
        }), 10;
      }
    };
    if (dataLength > DEFAULT_CHUNK_SIZE) {
      nextRender(DEFAULT_CHUNK_SIZE);
    } else {
      this.gridOptions.api.setRowData(this.rowData)
    }
  }
  getEnvironment() {
    const url = location.host;
    if(url.indexOf('localhost') !== -1) {
        return 'LOCAL'
    } else if (url.indexOf('qa') !== -1) {
        return 'QA';
    } else if (url.indexOf('cat') !== -1) {
        return 'CAT';
    } else {
        return 'PROD';
    }
}
}
