import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BaseUrlService {

    constructor() {
    }

    getBaseURL() {
      const env = this.getEnvironment();
      if(env === 'LOCAL') {
        return 'https://qa-fiapi.firstdata.com';
      } else {
        return '';
      }
    }

    getEnvironment() {
        const url = location.host;
        if(url.indexOf('localhost') !== -1) {
            return 'LOCAL'
        } else if (url.indexOf('qa') !== -1) {
            return 'QA';
        } else if (url.indexOf('cat') !== -1) {
            return 'CAT';
        } else {
            return 'PROD';
        }
    }

    getToken() {
          let tokenEnv = '';
          const env = this.getEnvironment();
        if (env === 'LOCAL') {
            tokenEnv = 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.AfYsCO3kcdexr2Y5IZyeWVw7mCNQVMoKOtGYqGMq-_g';
        } else if (env === 'QA') {
            tokenEnv = 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.AfYsCO3kcdexr2Y5IZyeWVw7mCNQVMoKOtGYqGMq-_g';
        } else if (env === 'CAT') {
            tokenEnv = 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.IvZnkj_71CNM2ZHyiCg1QBs0JELrk7bGPXzssBy9UWA';
        } else {
            tokenEnv = 'eyJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6ImRldlBvcnRhbCIsIng1MDBJZCI6IkFBQUExMTE0IiwiZ3JvdXBzIjpbIkRFVkVMT1BFUi1QT1JUQUwiXSwiaXNzIjoiTlNBIiwiZXhwIjo0MDk5MTU5MDc0LCJuYmYiOjE1OTkxNTgxNzQsImp0aSI6IjFkMDY3MDIyLTgzZjEtNDRjMC04NjcwLTIyODNmMWY0YzA1YiJ9.4-sdLuOlefbHiG8RFKdbUKX2sCV60jpdpGMssm667qw';
        }
        return tokenEnv;
    }

    includeHiddenApis() {
        const env = this.getEnvironment();
        return env !== 'LOCAL' && env !== 'QA';
    }
}
