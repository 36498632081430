import {Component, OnInit, OnDestroy} from '@angular/core';
import {ThemeManagerService} from '../_services/theme-manager.service';
import {Router} from '@angular/router';
import {BaseUrlService} from '../_services/base-url.service';
import {UtilsService} from '../_services/utils.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

    theme: ThemeManagerService;
    currentTheme: string;
    model = {
        light: true,
        dark: false
    };
    router: Router;
    startLoadingSubscription: any;
    doneLoadingSubscription: any;
    isLoading: boolean;
    utils: any;

    constructor(theme: ThemeManagerService, router: Router, private utilsService: UtilsService) {
        this.theme = theme;
        this.router = router;
        this.utils = utilsService;
    }

    setModelTheme(theme) {
        if (theme === 'light') {
            this.model.light = true;
            this.model.dark = false;
        } else if (theme === 'dark') {
            this.model.light = false;
            this.model.dark = true;
        }
    }

    setTheme(theme) {
        this.setModelTheme(theme);
        this.theme.setTheme(theme);
        this.currentTheme = this.theme.getTheme();
    }

    ngOnInit() {
        this.theme.setTheme('light');
        this.currentTheme = this.theme.getTheme();
        this.isLoading = false;
        this.startLoadingSubscription = this.utilsService.startLoadingEmitter
            .subscribe(() => this.isLoading = true);
        this.doneLoadingSubscription = this.utilsService.doneLoadingEmitter
            .subscribe(() => this.isLoading = false);
    }

    ngOnDestroy() {
        this.startLoadingSubscription.unsubscribe();
        this.doneLoadingSubscription.unsubscribe();
    }

    getCurrentRouterPath() {
        return this.router.url;
    }

    getCurrentDate() {
        return new Date().getFullYear()
    }

}
