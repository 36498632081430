import {Component, OnInit, OnDestroy} from '@angular/core';
import {ThemeManagerService} from '../_services/theme-manager.service';
import {Router} from '@angular/router';
import {UtilsService} from '../_services/utils.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    theme: ThemeManagerService;
    currentTheme: string;
    subscription: any;
    utilsService: UtilsService;

    constructor(theme: ThemeManagerService, private router: Router, utilsService: UtilsService) {
        this.theme = theme;
        this.utilsService = utilsService;
    }

    ngOnInit() {
        this.subscription = this.theme.getThemeChangeEmitter()
            .subscribe(item => this.currentTheme = item);
        this.theme.setTheme('light');
        this.currentTheme = this.theme.getTheme();
        this.router.navigate(['']).then(
            (resp) => {
                console.log('navigated');
            }
        );
        const engine = window.navigator.userAgent;
        if (engine.indexOf("Trident") !== -1) {
            this.router.navigate(['unsupported-browser']);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
