import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import * as mappingData from "../../../assets/js/api-data/EMEA/mappings.json";
import {KeyValue} from "@angular/common";

@Component({
    selector: 'app-emeajourney',
    templateUrl: './emeajourney.component.html',
    styleUrls: ['./emeajourney.component.scss']
})
export class EMEAjourneyComponent implements OnInit {

    mappingsData: any = mappingData;
    environment = window.location.href;
    showAllSections: boolean = true;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        for (let entry of this.mappingsData.default) {
            for (let tag of entry.tags) {
                tag.checked = false;
            }
        }
    }

    toggleClass(event, filter) {
        if (event.target.className === 'fas collapsible-icons fa-angle-up') {
            event.target.className = 'fas collapsible-icons fa-angle-down';
            document.getElementById('collapsible_' + filter).className = 'collapse hide row';
            console.log(document.getElementById('collapsible_' + filter).className);
        } else {
            event.target.className = 'fas collapsible-icons fa-angle-up';
            document.getElementById('collapsible_' + filter).className = 'collapse show row';
        }
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    filterSelection() {
        this.showAllSections = false;
        let noneChecked = true;
        for (let entry of this.mappingsData.default) {
            for (let tag of entry.tags) {
                if (tag.checked) {
                    noneChecked = false;
                }
            }
        }
        if (noneChecked) {
            this.showAllSections = true;
        }
    }

    showAll() {
        this.showAllSections = true;
        for (let entry of this.mappingsData.default) {
            for (let tag of entry.tags) {
                tag.checked = false;
            }
        }

        this.filterSelection();
    }

    isQAOrLocal() {
        return ((this.environment.indexOf('https://qa-fiapi.firstdata.com') > -1) ||
            (this.environment.indexOf('http://localhost') > -1));
    }

}
