<div class="main_container">
    <div class="search-header-section">

        <app-toolbar></app-toolbar>

    </div>
    <div class="container-fluid pt-3 bg-white">
        <div id="page-content-wrapper" class="search-results">
            <div class="row mt-3 mb-5">
                <div class="col col-sm-12">

                    <div class="card w-auto p-3">

                        <div class="card-header card-block text-center">
                            {{modelFound.fileName}} ({{modelFound.fileNumber}})
                        </div>

                        <div class="card-body">
                            {{modelFound.description}}
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col col-sm-9">
                <input class="form-control" type="text" id="filter-text-box" placeholder="Type to filter any column"
                    (input)="onFilterTextBoxChanged()" />
            </div>
            <div class="col col-sm-3">
                <button class="btn btn-warning mr-2" (click)="resetAllFilters()">Clear all Filters</button>
                <button class="btn btn-primary" (click)="back()">Back</button>
            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col col-sm-12">
                <ag-grid-angular #agGrid style="width: 100%; height: 500px; margin-bottom: 50px; margin-top: 50px"
                    id="myGrid" class="ag-theme-alpine cursor-default" [rowData]="rowData" [gridOptions]="gridOptions">
                </ag-grid-angular>
            </div>
        </div>

    </div>
</div>