import { Component, OnInit } from '@angular/core';
declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-swagger-ui-test',
  templateUrl: './swagger-ui-test.component.html',
  styleUrls: ['./swagger-ui-test.component.scss']
})
export class SwaggerUiTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: '../...//mocks/Memos.yaml',
      docExpansion: 'none',
      operationsSorter: 'alpha'
    });
  }

}
