<nav class="navbar navbar-expand-md " aria-label="Fourth navbar example">
    <div class="container-fluid">
        <a class="navbar-brand" (click)="this.utils.navigateToPage('/product/IssuerSolutions')">
            <svg width="352" height="60" fill="none" xmlns="http://www.w3.org/2000/svg" id="logo-icon" class="navbar-brand my-0 mr-md-auto fw-normal">
                <path d="M102.633 44.757h-6.54v6.539h6.54v-6.539zM82.8 15.596l3.888 29.25 3.933-29.25h6.053l-5.7 35.7H82.36l-5.656-35.7H82.8zM3.358 15.596H0v4.728h3.358v30.972h5.92V20.324h4.153v-4.728H9.279V8.35c0-1.06.221-1.855.752-2.43.53-.53 1.28-.795 2.341-.795h1.06V.265h-2.164c-2.651 0-5.17.574-6.363 1.723-1.193 1.149-1.546 2.916-1.546 5.479v8.13zM21.249 15.596h-5.92V51.34h5.92V15.596zM48.691 35.479h12.371V24.477c0-6.583-3.18-9.278-9.19-9.278-6.23 0-9.145 2.695-9.145 9.322v18.115c0 6.053 3.667 9.146 9.145 9.146 5.479 0 9.19-2.563 9.19-9.146v-2.872H55.32v3.402c0 2.43-.928 4.11-3.314 4.11-2.43 0-3.314-1.68-3.314-4.11V35.48zm0-12.195c0-2.032.53-4.109 3.314-4.109 2.783 0 3.358 2.077 3.358 4.11v7.378h-6.628v-7.379h-.044zM34.64 30.972c-4.463-3.932-4.86-4.463-4.86-7.379 0-2.474.22-3.976 2.43-3.976 2.208 0 2.517 1.502 2.517 4.02v3.314h5.744v-5.39c0-4.241-3.27-6.362-8.394-6.362-5.037 0-8.307 1.679-8.351 8.439v.707c0 2.12 0 5.478 5.258 9.985l1.502 1.281c3.888 3.27 4.286 3.667 4.286 7.555 0 2.386-.133 4.198-2.651 4.198-2.519 0-2.651-1.812-2.651-2.96v-4.596h-6.01v4.728c0 5.788 3.756 7.246 8.66 7.246 4.905 0 8.66-1.458 8.66-8.925v-.618c0-5.214-.927-6.628-6.14-11.267zM69.013 19.264v-3.668h-5.435v35.7h5.832V26.377c0-1.944.619-3.181 1.723-3.932 1.017-.707 2.298-.972 3.933-.972v-6.318c-3.093.044-4.419.662-6.01 3.976l-.043.133z" fill="#F60"></path>
            </svg>
        </a>
        <div class="collapse navbar-collapse d-flex justify-content-end">
        </div>
    </div>
</nav>