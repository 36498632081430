<div class="container-fluid pt-3">
    <div id="page-content-wrapper" class="search-results">
        <div class="row mb-5">
            <div class="col col-sm-3">

                <div class="card">
                    <div class="card-header">
                        Original Search
                    </div>
                    <div class="card-body">
                        {{modelFound.searchType}} | {{modelFound.inputName}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        Open Data Streams (ODS) Transaction
                    </div>
                    <div class="card-body text-dark">
                        {{modelFound.odsTran}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        ODS Type
                    </div>
                    <div class="card-body text-dark">
                        {{modelFound.odsType}}
                    </div>
                </div>
            </div>

            <div class="col col-sm-3">
                <div class="card">
                    <div class="card-header">
                        RESTful Web Service (API)
                    </div>
                    <div class="card-body text-dark">
                        <a class="link-primary" (click)="swaggerLink()">{{modelFound.api}}</a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="row mt-3 mb-5">
        <div class="col col-sm-9">
            <input class="form-control" type="text" id="filter-text-box" placeholder="Type to filter any column"
                (input)="onFilterTextBoxChanged()" />
        </div>
        <div class="col col-sm-3">
            <button class="btn btn-warning mr-2" (click)="resetAllFilters()">Clear all Filters</button>
            <button class="btn btn-primary mr-2" (click)="back()">Back</button>
            <button class="btn btn-warning" (click)="onBtExport()">Download</button>
        </div>
    </div>

    <div class="row mt-3 mb-5">
        <div class="col col-sm-12">
            <ag-grid-angular #agGrid style="width: 100%; height: 500px; margin-bottom: 50px; margin-top: 50px"
                id="myGrid" class="ag-theme-alpine cursor-pointer" [rowData]="rowData" [gridOptions]="gridOptions">
            </ag-grid-angular>
        </div>
    </div>

    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

</div>

<ng-template #apiModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-swagger-ui-test></app-swagger-ui-test>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>