import {Injectable, EventEmitter} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeManagerService {
    themeChange: EventEmitter<string> = new EventEmitter();
    private currentTheme = 'login';

    constructor() {
    }

    setTheme = (theme) => {
        this.currentTheme = theme;
        this.changeBackgroundTheme(theme);
    }

    getTheme = () => {
        return this.currentTheme;
    }

    getThemeChangeEmitter() {
        return this.themeChange;
    }

    changeBackgroundTheme = (theme) => {
        const html = document.getElementsByTagName('html')[0];
        document.getElementsByTagName('body')[0].setAttribute('class', theme);
        if (theme === 'dark') {
            html.setAttribute('class', 'dark');
        } else if (theme === 'light') {
            html.setAttribute('class', 'light');
        } else {
            html.setAttribute('class', 'login');
        }
        this.themeChange.emit(theme);
    }
}
