import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../_services/utils.service';
import { Router } from '@angular/router';
import { BaseUrlService } from '../_services/base-url.service';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

@Component({
  selector: 'app-api-search-details',
  templateUrl: './api-search-details.component.html',
  styleUrls: ['./api-search-details.component.scss']
})

export class ApiSearchDetailsComponent implements OnInit {
  @ViewChild('apiModal') apiModal: any;
  isLoading = false;
  data: any;
  apiMappings: any;
  searchTable: any = [];
  showConfiguration: boolean;
  utilService: any;
  api: any;
  modelFound = {
    odsTran: '',
    odsType: '',
    api: '',
    searchType: '',
    inputName: ''
  }
  gridOptions: any = {
    enableBrowserTooltips: true,
    columnDefs: [
      { headerName: 'ODS FIELD NAME', field: 'fieldName', sortable: true, filter: true, tooltipField: 'fieldName', minWidth: 160 },
      { headerName: 'API FIELD NAME', field: 'apiFieldName', sortable: true, filter: true, tooltipField: 'apiFieldName', minWidth: 150 },
      { headerName: 'BUSINESS NAME', field: 'businessName', sortable: true, filter: true, tooltipField: 'businessName', minWidth: 160 },
      { headerName: 'DESCRIPTION', field: 'description', sortable: true, filter: true, tooltipField: 'description', minWidth: 130 },
      { headerName: 'FIELD LENGTH', field: 'fieldLength', sortable: true, filter: true, minWidth: 140, type: 'rightAligned' },
      { headerName: 'DEC POS', field: 'decimalPos', sortable: true, filter: true, minWidth: 110, type: 'rightAligned' },
      { headerName: 'FILE NAME', field: 'fileNameId', valueGetter: this.abValueGetter, minWidth: 120, tooltipField: 'fileNameId', sortable: true, filter: true },
      { headerName: 'FILE OFFSET', field: 'fileOffsetDec', valueGetter: this.nullValueGetter, sortable: true, filter: true, minWidth: 120, type: 'rightAligned' },
      { headerName: 'DATA FORMAT', field: 'dateFormat', sortable: true, filter: true, minWidth: 140 },
      { headerName: 'DATA TYPE', field: 'dataType', sortable: true, filter: true, minWidth: 120, tooltipField: 'dataType' },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true
    },
    rowSelection: 'single',
    getRowNodeId: (data) => {
      return data.fieldName;
    },
    onSelectionChanged: () => {
      this.goToDevStudioSwagger(this.api);
    },
    onGridReady: (params) => {
      this.fetchRowData();
      params.api.setRowData(this.rowData);
    },
    overlayLoadingTemplate:
      '<span>No Records to Show</span>',
    overlayNoRowsTemplate:
      '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="sr-only">Loading...</span></div>',
  };

  rowData: any = [];
  httpOptions: any

  constructor(private modalService: NgbModal, utilService: UtilsService, private router: Router, private baseUrlService: BaseUrlService, private http: HttpClient) {
    this.utilService = utilService;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.modelFound.searchType = sessionStorage.getItem('searchType');
    this.modelFound.inputName = sessionStorage.getItem('inputName');
    this.modelFound.odsTran = sessionStorage.getItem('odsTran');
    if(this.modelFound.odsTran === null) {
      this.router.navigate(['NA/search']);
    }
    this.api = sessionStorage.getItem('api');
    this.modelFound.odsType = sessionStorage.getItem('odsType');
    this.modelFound.api = sessionStorage.getItem('api');
    const token = this.baseUrlService.getToken();
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
  }

  fetchRowData() {
    const body = {
      odsTran: this.modelFound.odsTran,
      apiName: this.api,
    };
    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/searchFieldsByOdsTran',
      body, this.httpOptions
    )
      .subscribe(
        data => {
          this.updateTableResults(data);
        },
        error => {
          console.log('Error', error);
          const errDiv = document.getElementById('create-error');
          if (errDiv) {
            errDiv.innerText = error.message;
            this.searchTable = [];
            this.rowData = [];
          }
        }
      );
  }

  onBtExport() {
      var params = {
        processCellCallback: this.processCells,
      };
      this.gridOptions.api.exportDataAsCsv(params);
    }

  processCells(cellParams: any) {
      if (
        cellParams &&
        cellParams.column.colId === 'dataHub' &&
        cellParams.value !== undefined
      ) {
        return cellParams.value.toLowerCase();
      } else return cellParams.value;
    }

  updateTableResults(data) {
    this.data = data;
    this.showConfiguration = true;
    this.searchTable = this.data.results;
    this.isLoading = false;
    if (!this.searchTable) {
      this.gridOptions.api.showLoadingOverlay();
      return;
    }

    if (this.modelFound.odsType === 'RPC') {
      document.getElementsByClassName('ag-header-cell-text')[6].textContent = 'Required'
      this.gridOptions.columnApi.applyColumnState({
        state: [{ colId: 'fileNameId', sort: 'desc' }],
        defaultState: { sort: null },
      });
      this.gridOptions.columnApi.autoSizeColumns(['fileNameId'])
    } else {
      this.gridOptions.columnApi.applyColumnState({
        state: [{ colId: 'fieldName', sort: 'asc' }],
        defaultState: { sort: null },
      });
    }
    if (this.searchTable === undefined) {
      this.gridOptions.suppressNoRowsOverlay = false;
    } else if (this.searchTable !== undefined) {
      this.gridOptions.suppressNoRowsOverlay = true;
      for (const item of this.searchTable) {
        const row = {
          apiFieldName: '',
          businessName: '',
          description: '',
          validValues: '',
          fieldLength: '',
          decimalPos: '',
          fieldName: '',
          fileOffsetDec: '',
          dateFormat: '',
          dataType: '',
          fileNameId: ''
        };
        for (const [key, value] of Object.entries(item)) {
          row[key] = value as string;

        }
        this.rowData.push(row);
      }
    }
    this.renderInChunks()
  }

  renderInChunks() {
    const DEFAULT_CHUNK_SIZE = 100;
    const dataLength = this.rowData.length;
    let results = [].concat(this.rowData)

    const nextRender = (howMany) => {
      var chunkSize = howMany || DEFAULT_CHUNK_SIZE;

      this.gridOptions.api.applyTransaction({ add: results.slice(0, howMany) });
      results.splice(0, chunkSize);

      if (results.length) {
        const howMany = results.length > DEFAULT_CHUNK_SIZE ? DEFAULT_CHUNK_SIZE : results.length
        setTimeout(() => {
          nextRender(howMany)
        }), 10;
      }
    };

    if (dataLength > DEFAULT_CHUNK_SIZE) {
      nextRender(DEFAULT_CHUNK_SIZE);
    } else {
      this.gridOptions.api.setRowData(this.rowData)
    }
  }

  abValueGetter(params) {
    if (params.data.odsType === 'RPC') {
      return params.data.fileName;
    } else {
      return params.data.fileName + '(' + params.data.fileId + ')';
    }
  }

  nullValueGetter(params) {
    if (params.data.fileOffsetDec.indexOf('null') > -1) {
      return '';
    } else {
      return params.data.fileOffsetDec;
    }
  }

  openApiModal(content) {
    this.modalService.open(content, { size: 'xl', scrollable: true });
  }

  onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
  }

  resetAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    (document.getElementById('filter-text-box') as HTMLInputElement).value = '';
    this.onFilterTextBoxChanged();
  }

  back() {
    window.history.back();
  }

  goToDevStudioSwagger(apiName) {
    const pathName = "/product/IssuerSolutions/api/?type=post&path=" + apiName;
    this.utilService.navigateToPage(pathName);
  }

  swaggerLink() {
    this.goToDevStudioSwagger(this.modelFound.api);
  }
}
