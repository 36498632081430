import {Component, HostBinding, TemplateRef} from '@angular/core';

import {ToastService} from '../_services/toast.service';

@Component({
    selector: 'app-toasts',
    template: `
        <ngb-toast
                *ngFor="let toast of toastService.toasts"
                [class]="toast.classname"
                [autohide]="true"
                [delay]="toast.delay || 5000"
                (hide)="toastService.remove(toast)"
                header="Close"
        >
            <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
                <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
            </ng-template>

            <ng-template #text>{{ toast.textOrTpl }}</ng-template>
        </ngb-toast>
    `
})
export class ToastsContainerComponent {
    @HostBinding('class.ngb-toasts') role = 'alert';

    constructor(public toastService: ToastService) {
    }

    isTemplate(toast) {
        return toast.textOrTpl instanceof TemplateRef;
    }
}
