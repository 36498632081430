import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlService } from '../_services/base-url.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-api-search',
  templateUrl: './api-search.component.html',
  styleUrls: ['./api-search.component.scss']
})
export class ApiSearchComponent implements OnInit {
  token: any = '';
  data1: any = [];
  autoComplete: any;
  utilService: any;
  data: any = [];
  showConfiguration: boolean;
  httpOptions: any = {};
  token1: any;
  model = {
    searchType: 'API',
    inputName: '',
    somePlaceholder: 'Ex. "/account/v4/accountRelationships"',
    orginialSearch: ''
  };
  searchTable: any = [];
  dataTable: any = [];
  dataLoad: any = [];
  autoCompleteMinCharFilterLimit = 3;
  excludeFromResponse: boolean;

  gridOptions: any = {
    enableBrowserTooltips: true,
    columnDefs: [
      { headerName: 'LIFECYCLE', field: 'lifecycle', sortable: true, filter: true, tooltipField: 'lifecycle' },
      { headerName: 'CATEGORY', field: 'category', sortable: true, filter: true, tooltipField: 'category' },
      { headerName: 'ODS TRANSACTION NAME', field: 'odsTran', sortable: true, filter: true, minWidth: 210, tooltipField: 'odsTran' },
      { headerName: 'ODS TYPE', field: 'odsType', sortable: true, filter: true, tooltipField: 'odsType' },
      { headerName: 'ODS DEFINITION', field: 'odsDefinition', sortable: true, filter: true, minWidth: 150, tooltipField: 'odsDefinition' },
      { headerName: 'API NAME', field: 'api', sortable: true, filter: true, tooltipField: 'api' },
      { headerName: '3270 FUNCTION', field: 'x3270Function', sortable: true, filter: true, minWidth: 160, tooltipField: '3270 Function' },
      { headerName: 'OCS SECURITY ALIAS', field: 'securityAlias', sortable: true, filter: true, minWidth: 150, tooltipField: 'OCS Security Alias' },
    ],
    defaultColDef: { flex: 1, minWidth: 100, resizable: true },
    rowSelection: 'single',
    onSelectionChanged: () => {
      this.router.navigate(['NA/search-results']).then(
        (resp) => {
          console.log('navigated');
        }
      );
    },
    onRowClicked(event: any) {
      sessionStorage.setItem('odsTran', event.data.odsTran);
      sessionStorage.setItem('api', event.data.api);
      sessionStorage.setItem('odsType', event.data.odsType);
      const filterModel = this.api.getFilterModel();
      sessionStorage.setItem('saveFilterModelJson', JSON.stringify(filterModel));
    },
   
     onGridReady: (event: any) => {
       if(this.searchTable === undefined){
        setTimeout(() => {
            document.getElementById('searchBtn').click()
        }, 10)
      }
         //Clean-up after restore filters have been performed.
   const savedFilterJson = sessionStorage.getItem('saveFilterModelJson')
   savedFilterJson && setTimeout(() => {
       sessionStorage.setItem('saveFilterModelJson', null)
   }, 10)
 
     },
    
    overlayNoRowsTemplate:
    '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="sr-only">Loading...</span></div>',
    overlayLoadingTemplate:
      '<span>No Records to Show</span>',
   
  };
  rowData: any = [];
  constructor(utilService: UtilsService, private router: Router, private http: HttpClient, private baseUrlService: BaseUrlService) {
    this.excludeFromResponse = false;
    this.utilService = utilService;
  }

  ngOnInit() {

    this.token = this.baseUrlService.getToken();
    this.excludeFromResponse = this.baseUrlService.includeHiddenApis();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    const body = {excludeFromResponse: this.excludeFromResponse,};
    if (sessionStorage.getItem('autoCompleteList1') == null) {
        this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/autoComplete',
              body, httpOptions
            )
              .subscribe(
                data => {
                  this.data1 = data;
                  this.dataLoad = this.data1;
                  this.dataTable = this.dataLoad.apis
                  sessionStorage.setItem('autoCompleteList1', JSON.stringify(data));
                },
                error => {
                  const errDiv = document.getElementById('create-error');
                  if (errDiv) {
                    errDiv.innerText = error.message;
                  }
                }
              )
              .add(() => {
               this.restoreSessionSearch();
              });
    }
    else {
        this.data1 = JSON.parse(sessionStorage.getItem('autoCompleteList1'));
        this.dataLoad = this.data1;
        this.dataTable = this.dataLoad.apis
        this.restoreSessionSearch();
    }
  }

  placeHolderChange() {
    document.getElementById('filter-text-box').style.borderColor = '#ced4da';
    if (this.model.searchType === 'API') {
      this.model.somePlaceholder = 'Ex. "/account/v4/accountRelationships"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 3;
      this.dataTable = this.dataLoad.apis
    }
    else if (this.model.searchType === 'ODS_TRAN') {
      this.model.somePlaceholder = 'Ex. "ACCOUNT_RELATIONSHIPS"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 2;
      this.dataTable = this.dataLoad.odsTransactions
    }
    else if (this.model.searchType === 'X3270_FUNC') {
      this.model.somePlaceholder = 'Ex. "CAI"';
      this.model.inputName = '';
      this.dataTable = [];
    }
    else if (this.model.searchType === 'CATEGORY') {
      this.model.somePlaceholder = 'Ex. "Account"';
      this.model.inputName = '';
      this.autoCompleteMinCharFilterLimit = 3;
      this.dataTable = this.dataLoad.categories
    }
    else if (this.model.searchType === 'WILDCARD') {
      this.model.somePlaceholder = 'Ex. "Balance"'
      this.model.inputName = '';
      this.dataTable = [];
    }

    return this.dataTable;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return term.length < this.autoCompleteMinCharFilterLimit
          ? []
          : this.dataTable
            .filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      })
    )

  selectedItem(item){
    this.model.inputName = item.item
    document.getElementById('searchBtn').click()
  }

  onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    this.model.inputName = '';
    document.getElementById('searchBtn').click()
    document.getElementById('filter-text-box').style.borderColor = '#ced4da';
      }

  resetAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    (document.getElementById('filter-text-box') as HTMLInputElement).value = '';
    this.onFilterTextBoxChanged();
  }

  postToSearchApi = (body, httpOptions, currentTarget, enableFilterApiBySearchTerm = false) => {

    this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/searchApi',
      body, httpOptions
    )
      .subscribe(
        data => {
          this.data = data;
          this.showConfiguration = true;
          this.searchTable = this.data.results;
          if(enableFilterApiBySearchTerm && this.model.searchType === 'API' && this.searchTable !== undefined ) { 
            this.searchTable = this.data.results.filter(item => item.api.indexOf(this.model.inputName) > -1); } 
            else if(enableFilterApiBySearchTerm && this.model.searchType === 'ODS_TRAN' && this.searchTable !== undefined  ) { 
              this.searchTable = this.data.results.filter(item => item.odsTran.indexOf(this.model.inputName.toUpperCase()) > -1); }
            else { this.searchTable = this.data.results; 
            }
          if (this.gridOptions.api && this.searchTable === undefined) {
            this.gridOptions.api.showLoadingOverlay();
          }
          else if (this.searchTable !== undefined) {
            for (const item of this.searchTable) {
              const row = {
                lifecycle: '',
                category: '',
                function: '',
                odsTran: '',
                odsType: '',
                odsDefinition: '',
                api: '',
                x3270Function: '',
                accessMethod: '',
                securityAlias: ''
              };
              for (const [key, value] of Object.entries(item)) {
                row[key] = value as string;

              }
              this.rowData.push(row);
              setTimeout(() => {
                this.renderInChunks();
              }, 10)
            }
          }
        },
        error => this.genericSubmitError(error)
      ).add(() => {
        currentTarget.disabled = false;
      });
  }

  genericSubmitError(error) {
    console.log('Error', error);
    const errDiv = document.getElementById('create-error');
    if (errDiv) {
      errDiv.innerText = error.message;
      this.searchTable = [];
      this.rowData = [];
    }
  }

  renderInChunks() {
    const DEFAULT_CHUNK_SIZE = 100;
    const dataLength = this.rowData.length;
    let results = [].concat(this.rowData)
    const nextRender = (howMany) => {
      var chunkSize = howMany || DEFAULT_CHUNK_SIZE;
      this.gridOptions.api.applyTransaction({ add: results.slice(0, howMany) });
      results.splice(0, chunkSize);
      if (results.length) {
        const howMany = results.length > DEFAULT_CHUNK_SIZE ? DEFAULT_CHUNK_SIZE : results.length
        setTimeout(() => {
          nextRender(howMany)
        }), 10;
      }
    };
    const savedFilterJson = sessionStorage.getItem('saveFilterModelJson')
    if (dataLength > DEFAULT_CHUNK_SIZE) {
      nextRender(DEFAULT_CHUNK_SIZE);
      if (savedFilterJson) {
          this.gridOptions.api.setFilterModel(JSON.parse(savedFilterJson));
      }
    } else {
      this.gridOptions.api.setRowData(this.rowData)
      if (savedFilterJson) {
          this.gridOptions.api.setFilterModel(JSON.parse(savedFilterJson));
      }
    }
  }

  submit($event) {
    $event.currentTarget.disabled = true;
    document.getElementById('filter-text-box').style.borderColor = '#ced4da'
    sessionStorage.setItem('inputName', this.model.inputName);
    this.rowData = [];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token
      })
    };

    if (this.model.inputName === '') {
      $event.currentTarget.disabled = false;
      document.getElementById('filter-text-box').style.borderColor = 'red'
      if (this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.hideOverlay();
          this.gridOptions.api.showLoadingOverlay();
        }, 10)

      }
      return;
    }

    switch (this.model.searchType) {
      case 'ODS_TRAN': {
        this.model.orginialSearch = 'Open Data Streams (ODS) Transaction Names';
        const body = {
          searchType: this.model.searchType,
          odsTran: this.model.inputName.toUpperCase(),
          excludeFromResponse: this.excludeFromResponse,
        };
        if(this.model.inputName.toUpperCase() !== this.model.inputName.toLowerCase()) {
          this.postToSearchApi(body, httpOptions, $event.currentTarget);
      } else {
          //WILDCARD
          const body = {
            searchType: 'WILDCARD',
            wildCard: this.model.inputName.toUpperCase(),
            excludeFromResponse: this.excludeFromResponse,
          };
          this.postToSearchApi(body, httpOptions, $event.currentTarget, true);
      }
        break;
      }
      case 'CATEGORY': {
        this.model.orginialSearch = 'RESTful Web Services (API) Category Names';
        const body = {
          searchType: this.model.searchType,
          category: this.matchAutoComplete(this.model.inputName),
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      case 'X3270_FUNC': {
        this.model.orginialSearch = '3270 Screen/Function Names';
        const body = {
          searchType: this.model.searchType,
          x3270Func: this.model.inputName,
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      case 'WILDCARD': {
        this.model.orginialSearch = 'Comprehensive General Purpose Names';
        const body = {
          searchType: this.model.searchType,
          wildCard: this.model.inputName,
          excludeFromResponse: this.excludeFromResponse,
        };

        this.postToSearchApi(body, httpOptions, $event.currentTarget);
        break;
      }
      default: {
        // API
        this.model.orginialSearch = 'RESTful Web Services (API) Service Names';
        const body = {
          searchType: this.model.searchType,
          apiName: this.matchAutoComplete(this.model.inputName),
          excludeFromResponse: this.excludeFromResponse,
        };
        if(this.model.inputName.indexOf('/') > -1) {
            this.postToSearchApi(body, httpOptions, $event.currentTarget);
        } else {
            //WILDCARD
            const body = {
              searchType: 'WILDCARD',
              wildCard: this.model.inputName,
              excludeFromResponse: this.excludeFromResponse,
            };
            this.postToSearchApi(body, httpOptions, $event.currentTarget, true);
        }
        break;
      }
    }
    sessionStorage.setItem('searchType', this.model.orginialSearch);
    sessionStorage.setItem('searchTypeSelection', this.model.searchType);
  }

  restoreSessionSearch() {
    const searchType = sessionStorage.getItem('searchTypeSelection')
    const inputValue = sessionStorage.getItem('inputName')
    if (searchType && inputValue) {
        this.model.searchType = searchType
        this.model.inputName = inputValue

        if (searchType === 'API') {
        } else if (searchType === 'ODS_TRAN') {
            this.dataTable = this.dataLoad.odsTransactions
        } else if (searchType === 'CATEGORY') {
            this.dataTable = this.dataLoad.categories
        } else {
            this.dataTable = []
        }

        document.getElementById('searchBtn').click()
    }
  }
  matchAutoComplete(inputText) {
    const searchText = this.dataTable.find(x => x.toUpperCase() === inputText.toUpperCase());
    return searchText !== undefined ? searchText : inputText;
  }
}
