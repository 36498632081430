import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import * as mappingData from '../../../assets/js/api-data/NA/mappings.json';
import {KeyValue} from "@angular/common";

@Component({
    selector: 'app-naapibrowser',
    templateUrl: './naapibrowser.component.html',
    styleUrls: ['./naapibrowser.component.scss']
})
export class NAapibrowserComponent implements OnInit {

    mappingsData: any = mappingData;
    environment = window.location.href;
    showAllSections: boolean = true;
    locationUrl: any;
    prefix: String = "/product/IssuerSolutions/api/?type=post&path=";
    DEVSTUDIO_QA:String = "https://qa-developer.fiserv.com" + this.prefix;
    DEVSTUDIO_CAT:String = "https://stage-developer.fiserv.com" + this.prefix;
    DEVSTUDIO_PROD:String = "https://developer.fiserv.com" + this.prefix;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
                    this.locationUrl = location.host;
    }

    ngOnInit(): void {
        for (let entry of this.mappingsData.default) {
            entry.checked = false;
        }
    }

    toggleClass(event, filter) {
        if (event.target.className === 'fas collapsible-icons fa-angle-up') {
            event.target.className = 'fas collapsible-icons fa-angle-down';
            document.getElementById('collapsible_' + filter).className = 'collapse hide row';
            console.log(document.getElementById('collapsible_' + filter).className);
        } else {
            event.target.className = 'fas collapsible-icons fa-angle-up';
            document.getElementById('collapsible_' + filter).className = 'collapse show row';
        }
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    filterSelection() {
        this.showAllSections = false;
        let noneChecked = true;
        for (let entry of this.mappingsData.default) {
            if (entry.checked) {
                noneChecked = false;
            }
            const ele = document.getElementById('toggle-icon-' + entry.filter);
            if (ele) {
                ele.className = 'fas collapsible-icons fa-angle-up';
                document.getElementById('collapsible_' + entry.filter).className = 'collapse show row';
            }
        }

        if (noneChecked) {
            this.showAllSections = true;
        }
    }

    showAll() {
        this.showAllSections = true;
        for (let entry of this.mappingsData.default) {
            entry.checked = false;
        }

        this.filterSelection();
    }

    isQAOrLocal() {
        return ((this.environment.indexOf('https://qa-fiapi.firstdata.com') > -1) ||
            (this.environment.indexOf('http://localhost') > -1));
    }

    isCAT() {
        return (this.environment.indexOf('https://cat-fiapi.firstdata.com') > -1);
    }

    retrieveURL(path) {
        if (this.isQAOrLocal()) {
            return this.DEVSTUDIO_QA + path;
        } else if(this.isCAT()) {
            return this.DEVSTUDIO_CAT + path;
        } else {
            return this.DEVSTUDIO_PROD + path;
        }
    }
}
