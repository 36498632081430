<div class="main_container">
    <div id="use-cases-header-section">

        <app-toolbar></app-toolbar>

        <div class="use-cases-header-section__intro-title-section">
            <div>
                <p id="use-cases-header-section__intro-title"> Our services, directly into your hands</p>
            </div>
            <div>
                <p id="use-cases-header-section__intro-text">Browse our broad selection of Financial Service Use Cases
                </p>
            </div>
        </div>
    </div>
    <div class="use-cases-content-container">
        <div class="use-cases-grid-container" align="center">
            <div class="row">
                <div class="col-md-3 use-cases__solution-category">
                    <div id="use-cases__solution-category-filter-section" class="accordion-panel">
                        <div class="use-cases-filter-section">
                            <p id="use-cases__solution-category-filter-title">Use Case</p>
                            <p id="use-cases__solution-category-filter-text">Filter by</p>
                            <div class="use-cases_filters">
                            </div>
                            <div class="clear-button-section">
                                <div *ngFor="let item of mappingsData.default | keyvalue: originalOrder">
                                    <div *ngIf="item.value.unified_category !== ''">
                                        <div *ngFor="let tag of item.value.tags">
                                            <label class="use-cases__solution-category-filters">{{tag.display}}
                                                <input type="checkbox" name="Categories" value="{{tag.html}}"
                                                       (change)="filterSelection()" alt="{{tag.html}}"
                                                       [checked]="tag.checked" [(ngModel)]="tag.checked">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <button class="use-cases__clear-filters" (click)="showAll()">Clear all</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="use-cases__tile-section" class="col-md-9">

                    <div class="row use-cases__unified-section filterCat All">
                        <div class="col-12 use-cases__category-header-section">
                            <p class="use-cases__unified-category-header">Use Cases</p>
                        </div>
                    </div>

                    <div *ngFor="let item of mappingsData.default | keyvalue: originalOrder; let i = index">
                        <div *ngIf="item.value.unified_category !== ''">
                            <div *ngFor="let tag of item.value.tags | keyvalue">

                                <div id="use-cases-cards"
                                     class="filterCat All {{item.value.filter}} {{item.value.filter}}-FI_function"
                                     header-type="{{item.value.filter}}-header"
                                     business-function="{{tag.value.function}}"
                                     *ngIf="showAllSections || tag.value.checked">

                                    <div class="card" id="use-cases__tiles">
                                        <div class="card-body" id="tile-body">
                                            <h5 class="card-title">{{tag.value.display}}</h5>
                                            <h6 class="card-subtitle mb-2"
                                                id="use-cases__card-business-type">{{tag.value.function}}</h6>
                                        </div>
                                        <div id="links">
                                            <a class="card-link"
                                               href="{{isQAOrLocal() ? tag.value.QA_apiLink : tag.value.PROD_apiLink}}">
                                                View APIs </a>
                                            <a class="card-link"
                                               href="https://docs.firstdata.com/org/global/docs/user-manuals">
                                                Download </a>
                                            <a *ngIf="tag.value.examples !== ''" class="card-link"
                                               href="{{tag.value.examples}}"> Examples </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
