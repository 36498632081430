import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UtilsService} from '../_services/utils.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    utils: any;

    constructor(public router: Router, private utilsService: UtilsService) {
        this.utils = utilsService;
    }

    ngOnInit(): void {
    }

    displayMobileDropdown(){
        document.getElementById('mobileDropdown').classList.toggle('showDiv');
        document.getElementById('mobileDropdown').classList.toggle('d-flex');
        document.getElementById('dropdownBars').classList.toggle('showDiv');
        document.getElementById('dropdownBars').classList.toggle('inlineDiv');
        document.getElementById('dropdownX').classList.toggle('showDiv');
        document.getElementById('dropdownX').classList.toggle('inlineDiv');
    }

    toggleDarkMode(){
        document.body.classList.toggle('dark-theme');
        document.getElementById('main_container').classList.toggle('dark-theme');
        document.getElementById('headerBar').classList.toggle('header-dark-theme');
        document.getElementById('mobileDropdown').classList.toggle('dark-theme');
        document.getElementById('api-browser-content').classList.toggle('dark-theme');
        document.getElementById('footer').classList.toggle('footer-dark-theme');
        document.getElementById('footer2').classList.toggle('footer-dark-theme');
        document.getElementById('footer-section').classList.toggle('dark-theme');
        document.getElementById('footer-section2').classList.toggle('dark-theme');
        document.getElementById('dark-theme-toggle').classList.toggle('showDiv');
        document.getElementById('dark-theme-toggle-2').classList.toggle('showDiv');
        document.getElementById('dark-theme-toggle-3').classList.toggle('showDiv');
        document.getElementById('dark-theme-toggle-4').classList.toggle('showDiv');
        document.getElementById('lightWebby').classList.toggle('showDiv');
        document.getElementById('darkWebby').classList.toggle('showDiv');
    }

}
