import {
    Component,
    OnInit
  } from '@angular/core';
  import {
    UtilsService
  } from '../_services/utils.service';
  import {
    Router
  } from '@angular/router';
  import {
    HttpClient,
    HttpHeaders
  } from '@angular/common/http';
  import {
    BaseUrlService
  } from '../_services/base-url.service';
  

  @Component({
    selector: 'app-file-search-details-tree',
    templateUrl: './file-search-details-tree.component.html',
    styleUrls: ['./file-search-details-tree.component.scss']
  })
  export class FileSearchDetailsTreeComponent implements OnInit {
  
    modelFound = {
      fileNumber: '',
      description: '',
      fileName: '',
      api: '/account/v4/accountRelationships'
    };
    apiMappings: any;
    token: any;
    utilService: any;
    searchTable: any = [];
    rowData: any = [];
    gridOptions: any = {
      enableBrowserTooltips: true,
      columnDefs: [{
          headerName: 'FIELD NAME',
          field: 'fieldName',
          sortable: true,
          filter: true,
          tooltipField: 'fieldName',
          hide: true,
          suppressToolPanel: true
        },
        {
          headerName: 'BUSINESS NAME',
          field: 'businessName',
          sortable: true,
          filter: true,
          tooltipField: 'businessName'
        },
        {
          headerName: 'DESCRIPTION',
          field: 'description',
          sortable: true,
          filter: true,
          tooltipField: 'description',
          minWidth: 300
        },
        {
          headerName: 'FIELD VALUES',
          field: 'fieldValues',
          sortable: true,
          filter: true,
          tooltipField: 'fieldValues',
        },
        {
          headerName: 'BEGIN',
          field: 'beginPos',
          sortable: true,
          filter: true,
          tooltipField: 'beginPos',
          type: 'rightAligned',
          maxWidth: 100
        },
        {
          headerName: 'LENGTH',
          field: 'length',
          sortable: true,
          filter: true,
          tooltipField: 'length',
          type: 'rightAligned',
          maxWidth: 100
        },
        {
          headerName: 'FORMAT',
          field: 'format',
          sortable: true,
          filter: true,
          tooltipField: 'format',
          maxWidth: 100
        }
      ],
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: this.getIndentClass,
        headerName: 'HIERARCHY',
        minWidth: 300,
        cellRendererParams: {
          suppressCount: true,
        },
      },
      treeData: true,
      animateRows: true,
      groupDefaultExpanded: -1,
    getDataPath: function (data) {
      return data.hierarchy;
    },
      onGridReady: (params) => {
        this.fetchRowData();
        params.api.setRowData(this.rowData);
      },
      excelStyles: [
        {
          id: 'indent-1',
          alignment: {
            indent: 1,
          },
          dataType: 'string',
        },
        {
          id: 'indent-2',
          alignment: {
            indent: 5,
          },
          dataType: 'string',
        },
        {
          id: 'indent-3',
          alignment: {
            indent: 9,
          },
          dataType: 'string',
        },
      ],
      overlayLoadingTemplate: '<span>No Records to Show</span>',
      overlayNoRowsTemplate: '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="sr-only">Loading...</span></div>',
    };
  
    constructor(utilService: UtilsService, private router: Router, private http: HttpClient, private baseUrlService: BaseUrlService) {
      this.utilService = utilService;
    }
  
  
    ngOnInit(): void {
      this.modelFound.fileNumber = sessionStorage.getItem('fileNumber');
      this.modelFound.description = sessionStorage.getItem('description');
      this.modelFound.fileName = sessionStorage.getItem('fileName');
      const token = this.baseUrlService.getToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: token
        })
      };
    }

    getIndentClass(params) {
      var indent = 0;
      var node = params.node;
      while (node && node.parent) {
        indent++;
        node = node.parent;
      }
      return ['indent-' + indent];
    }
  
    fetchRowData() {
      this.token = this.baseUrlService.getToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.token
        })
      };
  
      const body = {
        fileNumber: this.modelFound.fileNumber,
        isTreeView: true
      };
      this.http.post(this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/findBatchFileById',
          body, httpOptions
         ) 
        .subscribe(
          data => {
            this.searchTable = data;
            this.searchTable = this.searchTable.results;
            if (this.gridOptions.api && this.searchTable === undefined) {
              this.gridOptions.api.showLoadingOverlay();
            } else if (this.searchTable !== undefined) {
              this.searchTable.sort((a, b) => (a.beginPos > b.beginPos) ? 1 : (a.beginPos === b.beginPos) ? ((a.length < b.length) ? 1 : -1) : -1);
              for (const item of this.searchTable) {
                this.rowData.push(item);
              }
              this.renderInChunks();
            }
          },
          error => {
            if (error.status != 200) {
              this.gridOptions.api.showLoadingOverlay();
              }
          }
        )
    }
  
    onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }
  
    resetAllFilters() {
      this.gridOptions.api.setFilterModel(null);
      (document.getElementById('filter-text-box') as HTMLInputElement).value = '';
      this.onFilterTextBoxChanged();
    }
    back() {
      window.history.back();
    }
    renderInChunks() {
      const DEFAULT_CHUNK_SIZE = 100;
      const dataLength = this.rowData.length;
      let results = [].concat(this.rowData)
      const nextRender = (howMany) => {
        var chunkSize = howMany || DEFAULT_CHUNK_SIZE;
        this.gridOptions.api.applyTransaction({
          add: results.slice(0, howMany)
        });
        results.splice(0, chunkSize);
        if (results.length) {
          const howMany = results.length > DEFAULT_CHUNK_SIZE ? DEFAULT_CHUNK_SIZE : results.length
          setTimeout(() => {
            nextRender(howMany)
          }), 10;
        }
      };
      if (dataLength > DEFAULT_CHUNK_SIZE) {
        nextRender(DEFAULT_CHUNK_SIZE);
      } else {
        this.gridOptions.api.setRowData(this.rowData)
      }
    }
    goToSwagger(category: string, apiname: string) {
      const res = this.apiMappings.categories.map(item => {
        return item.tags.filter(tag => {
          return tag.html.toLowerCase() === category.toLowerCase()
        })
      });
  
      const reducer = res.reduce((a, b) => a.concat(b), []);
      const env = this.baseUrlService.getEnvironment()
  
      if (reducer.length === 0) {
            window.open('https://docs.firstdata.com/org/usapi/docs/api#' + apiname.toLowerCase(), "_blank");
      } else {
          if (env === 'LOCAL' || env === 'QA') {
            window.open(reducer[0].qaApiLink + '#' + apiname.toLowerCase(), "_blank");
          } else {
            window.open(reducer[0].prodApiLink + '#' + apiname.toLowerCase(), "_blank");
          }
      }
    }
  
    swaggerLink() {
      const apiArr = this.modelFound.api.split('/');
      const category = apiArr[1]
      const version = apiArr[2]
      const apiName = apiArr[3]
  
      const tag = category === "fiws" && version === "v1" ? "relay" : ( category === "non-monetary" ? "nonmonetary" : category )
      this.goToSwagger(tag, apiName);
    }

     onBtnExportDataAsExcel() {
      var params = this.getParams();
      this.gridOptions.api.exportDataAsExcel(
        params
      );
    }

    getParams() {
      return {
        allColumns: true,
        sheetName: this.modelFound.fileNumber,
        fileName: 'BATCH_FILE_' + this.modelFound.fileNumber
      }
    }
  }