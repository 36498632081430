import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    constructor() {
    }

    toggleSideMenu() {
        const ele = document.getElementById('wrapper');
        if (ele) {
            const isOpen = ele.getAttribute('class').indexOf('toggled') !== -1;
            if (isOpen) {
                ele.setAttribute('class', 'wrapper');
            } else {
                ele.setAttribute('class', 'wrapper toggled');
            }
        }
    }
}
