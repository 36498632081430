<div class="d-flex" id="wrapper">

    <!-- Page Content -->
    <div id="page-content-wrapper">

        <app-splash></app-splash>

        <router-outlet name="homeContainer"></router-outlet>

        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

    </div>
    <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->
