<div id="main_container" class="container-fluid page-container">
    <div id="header-section">

        <div id="logo-section" class="row">
            <svg width="103" height="60" fill="none" xmlns="http://www.w3.org/2000/svg" id="logo-icon" class="ml-3 mt-2">
                <path d="M102.633 44.757h-6.54v6.539h6.54v-6.539zM82.8 15.596l3.888 29.25 3.933-29.25h6.053l-5.7 35.7H82.36l-5.656-35.7H82.8zM3.358 15.596H0v4.728h3.358v30.972h5.92V20.324h4.153v-4.728H9.279V8.35c0-1.06.221-1.855.752-2.43.53-.53 1.28-.795 2.341-.795h1.06V.265h-2.164c-2.651 0-5.17.574-6.363 1.723-1.193 1.149-1.546 2.916-1.546 5.479v8.13zM21.249 15.596h-5.92V51.34h5.92V15.596zM48.691 35.479h12.371V24.477c0-6.583-3.18-9.278-9.19-9.278-6.23 0-9.145 2.695-9.145 9.322v18.115c0 6.053 3.667 9.146 9.145 9.146 5.479 0 9.19-2.563 9.19-9.146v-2.872H55.32v3.402c0 2.43-.928 4.11-3.314 4.11-2.43 0-3.314-1.68-3.314-4.11V35.48zm0-12.195c0-2.032.53-4.109 3.314-4.109 2.783 0 3.358 2.077 3.358 4.11v7.378h-6.628v-7.379h-.044zM34.64 30.972c-4.463-3.932-4.86-4.463-4.86-7.379 0-2.474.22-3.976 2.43-3.976 2.208 0 2.517 1.502 2.517 4.02v3.314h5.744v-5.39c0-4.241-3.27-6.362-8.394-6.362-5.037 0-8.307 1.679-8.351 8.439v.707c0 2.12 0 5.478 5.258 9.985l1.502 1.281c3.888 3.27 4.286 3.667 4.286 7.555 0 2.386-.133 4.198-2.651 4.198-2.519 0-2.651-1.812-2.651-2.96v-4.596h-6.01v4.728c0 5.788 3.756 7.246 8.66 7.246 4.905 0 8.66-1.458 8.66-8.925v-.618c0-5.214-.927-6.628-6.14-11.267zM69.013 19.264v-3.668h-5.435v35.7h5.832V26.377c0-1.944.619-3.181 1.723-3.932 1.017-.707 2.298-.972 3.933-.972v-6.318c-3.093.044-4.419.662-6.01 3.976l-.043.133z" fill="#F60"></path>
            </svg>
            <p class="logo-section__logo"> | </p>
            <p class="logo-section__caption">Fiserv Issuer Solutions</p>
            <p class="logo-section__caption">Developer Portal</p>
        </div>

        <div class='header-section__intro-section'>
            <div>
                <p id="header-section__intro-title">Take Your Imagination To Market</p>
            </div>
            <div id="intro-text-section">
                <p id="header-section__intro-text">With over 900 APIs, our financial platform enables you to bring your
                    ideas to life and build innovative
                    and completely customized apps for your financial institution.
                </p>
            </div>
        </div>

        <div class="main_container" id="carousel-div">
            <div id="carousel-section__container" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carousel-section__container" data-slide-to="0" class="active"></li>
                  <li data-target="#carousel-section__container" data-slide-to="1"></li>
                  <li data-target="#carousel-section__container" data-slide-to="2"></li>
                </ol>
                <div id="carousel-parent" class="carousel-inner">
                  <div class="carousel-item active">
                      <div id="api-all" class="carousel-image-div">
                        <img class="carousel-image-all" alt="..." src="/portal/assets/images/Over900Apis.png">
                      </div>
                  </div>
                  <div class="carousel-item">
                      <div id="mix-match" class="carousel-image-div">
                        <img class="carousel-image-mix" alt="..." src="/portal/assets/images/Mix-Match-Microservices.png">
                      </div>
                  </div>
                  <div class="carousel-item">
                      <div id="Globe" class="carousel-image-div">
                        <img class="carousel-image-globe" alt="..." src="/portal/assets/images/DebitCreditCommPrepaid.png">
                      </div>
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-section__container" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-section__container" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            <div class="centered">
                <a class="access-api-button" (click)="this.utils.navigateToPage('/product/IssuerSolutions')">View our APIs
                </a>
            </div>
            <div class="centered">
            <button  (click)="navigateToPage('/NA/file-search')" class="access-api-button">View Our Batch Files
            </button>
        </div>
        </div>
    </div>

    <div id="sliders" class="slider-section">
        <h2 class="slider-section__heading">The Fiserv Difference</h2>

        <div class="space"></div>

        <div id="slider-1" class="slide-right-wrap" data-aos="slide-right">
            <div class="slider-section__image-container" id="slider-image-1">
                <img class="slider-section__image" src="/portal/assets/images/simplify_icon_D.png">
            </div>
            <div id="slider-heading-1">
                <h3 class="slider-heading">Streamline & Simplify</h3>
            </div>
            <div class="slider-section__text-container" id="slider-section-1">
                <p class="slider-section__text">Fiserv's API Development Portal unlocks a world of possibility for
                    real-time sharing of data and processes
                    with Fiserv. as well as customizing our solutions to fit your needs.</p>
                <br>
                <p class="slider-section__text">Our automated and interoperable API portal helps facilitate integration,
                    as well as reduce the time needed
                    to execute tasks.</p>
            </div>
        </div>

        <div class="space"></div>

        <div id="slider-2" class="slide-left-wrap" data-aos="slide-left">
            <div class="slider-section__image-container" id="slider-image-2">
                <img class="slider-section__image" src="/portal/assets/images/laptop_mockup_D.png">
            </div>

            <div id="slider-heading-2">
                <h3 class="slider-heading">Form & Function</h3>
            </div>

            <div class="slider-section__text-container" id="slider-section-2">
                <p class="slider-section__text">Developers and Business Analysts can easily explore a range of features:
                </p>
                <p class="slider_bullets">
                    <i class="fas fa-check"></i>Custom APIs</p>
                <p class="slider_bullets">
                    <i class="fas fa-check"></i>Open Data Streams</p>
                <p class="slider_bullets">
                    <i class="fas fa-check"></i>Model Views</p>
                <p class="slider_bullets">
                    <i class="fas fa-check"></i>Format & Syntax Data</p>
            </div>
        </div>

        <div class="space"></div>

        <div id="slider-3" class="slide-right-wrap" data-aos="slide-right">
            <div class="slider-section__image-container" id="slider-image-3">
                <a href="#videoLink" class="open-link">
                    <img class="slider-section__image" src="/portal/assets/images/Fresh_Take.png">
                </a>
            </div>
            <div id="slider-heading-3">
                <h3 class="slider-heading">A Fresh Take</h3>
            </div>
            <div class="slider-section__text-container" id="slider-section-3">
                <p class="slider-section__text">Fiserv's financial services platform delivers solutions in:</p>
                <ul class="bullets">
                    <li>Financial Institution and retail credit card issuing</li>
                    <li>Commercial card processing, management, payment, electrification, and Cash management</li>
                </ul>
                <p class="slider-section__text">All working to help drive efficiency, risk, management, and customary
                    retention.
                </p>
            </div>

        </div>
        <div class="space"></div>

    </div>

    <div id="featured-apis" class="featured-apis-section">

        <div id="featTitle">
            <h1 id="featured-apis-section-title">Featured APIs</h1>
        </div>
        <div class="featured-apis-section__intro">
            <p class="featured-apis-section__intro-text">The Fiserv issuing platform has hundreds of APIs.
            </p>
            <p class="featured-apis-section__intro-text">To make it easy for you, here are a few we think you may need.
            </p>
        </div>
        <div id="featLabels" class="container">
            <div class="row">
                <div id="usAPI" class="col-md-6">
                    <p class="featured-apis-section__API_title">North America APIs</p>
                </div>
                <div id="intAPI" class="col-md-6">
                    <p class="featured-apis-section__API_title">EMEA APIs</p>
                </div>
            </div>
        </div>
        <div id="link" class="container">
            <div class="row featured-apis-section__icons">
                <div id="featured-api-section__account-inquiry" class="col-md-3 featured-apis-section__icons">
                    <a href="https://docs.firstdata.com/org/usapi/docs/api#v4-inquiry">
                        <div>
                            <img class="featimg" src="/portal/assets/images/Account_Inquiries_Icon_D.png">
                        </div>
                        <div id="featured-api-section__account-inquiry-text"
                             class="featured-api-section__text-container">
                            <p class="featured-apis-section__API_text"> Account Inquiry </p>
                        </div>
                    </a>
                </div>
                <div id="featured-api-section__balance-transfers" class="col-md-3 featured-apis-section__icons">
                    <a href="https://docs.firstdata.com/org/usapi/docs/api#v2-balanceconsolidationrelease">
                        <div>
                            <img class="featimg" src="/portal/assets/images/Balance_Transfers_Icon_D.png">
                        </div>
                        <div id="featured-api-section__balance-transfers-text"
                             class="featured-api-section__text-container">
                            <p class="featured-apis-section__API_text"> Balance Transfers </p>
                        </div>
                    </a>
                </div>
                <div id="featured-api-section__view-pin" class="col-md-3 featured-apis-section__icons">
                    <div id="featured-image-container">
                        <img class="featimg" src="/portal/assets/images/View_Pin_Icon_D.png">
                    </div>
                    <div id="featured-api-section__view-pin-text" class="featured-api-section__text-container">
                        <p class="featured-apis-section__API_text"> View Pin </p>
                    </div>
                </div>
                <div id="featured-api-section__card-embosser-update" class="col-md-3 featured-apis-section__icons">
                    <div>
                        <img class="featimg" src="/portal/assets/images/Card_Embosser_Icon_D.png">
                    </div>
                    <div id="featured-api-section__card-embosser-update-text"
                         class="featured-api-section__text-container">
                        <p class="featured-apis-section__API_text" align="center"> Card Embosser Update </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>