import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import * as mappingData from '../../../assets/js/api-data/EMEA/mappings.json';
import {KeyValue} from "@angular/common";

@Component({
    selector: 'app-emeaapibrowser',
    templateUrl: './emeaapibrowser.component.html',
    styleUrls: ['./emeaapibrowser.component.scss']
})
export class EMEAapibrowserComponent implements OnInit {

    mappingsData: any = mappingData;
    environment = window.location.href;
    showAllSections: boolean = true;
    businessCategory: string = '';

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        for (let entry of this.mappingsData.default) {
            entry.checked = false;
        }
    }

    toggleClass(event, filter) {
        if (event.target.className === 'fas collapsible-icons fa-angle-up') {
            event.target.className = 'fas collapsible-icons fa-angle-down';
            document.getElementById('collapsible_' + filter).className = 'collapse hide row';
            console.log(document.getElementById('collapsible_' + filter).className);
        } else {
            event.target.className = 'fas collapsible-icons fa-angle-up';
            document.getElementById('collapsible_' + filter).className = 'collapse show row';
        }
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    };

    filterSelection() {
        this.showAllSections = false;
        let noneChecked = true;
        for (let entry of this.mappingsData.default) {
            if (entry.checked) {
                noneChecked = false;
            }
            const ele = document.getElementById('toggle-icon-' + entry.filter);
            if (ele) {
                ele.className = 'fas collapsible-icons fa-angle-up';
                document.getElementById('collapsible_' + entry.filter).className = 'collapse show row';
            }
        }

        if (noneChecked) {
            this.showAllSections = true;
        }
    }

    showAll() {
        this.showAllSections = true;
        for (let entry of this.mappingsData.default) {
            entry.checked = false;
        }

        this.filterSelection();
    }

    isQAOrLocal() {
        return ((this.environment.indexOf('https://qa-fiapi.firstdata.com') > -1) ||
            (this.environment.indexOf('http://localhost') > -1));
    }

    setBusinessCategory(businessCategory) {
        if (businessCategory === this.businessCategory) {
            this.businessCategory = '';
        } else {
            this.businessCategory = businessCategory;
        }
    }

    businessFunction(businessCategory) {
        if (businessCategory === 'Credit') {
            document.getElementById('debit-button').className = 'api-browser__function-filter debit-toggle';
            document.getElementById('prepaid-button').className = 'api-browser__function-filter prepaid-toggle';
        } else if (businessCategory === 'Debit') {
            document.getElementById('credit-button').className = 'api-browser__function-filter credit-toggle';
            document.getElementById('prepaid-button').className = 'api-browser__function-filter prepaid-toggle';
        } else {
            document.getElementById('credit-button').className = 'api-browser__function-filter credit-toggle';
            document.getElementById('debit-button').className = 'api-browser__function-filter debit-toggle';
        }

        if (businessCategory === 'Credit') {
            this.setBusinessCategory(businessCategory);
        } else if (businessCategory === 'Debit') {
            this.setBusinessCategory(businessCategory);
        } else if (businessCategory === 'Prepaid') {
            this.setBusinessCategory(businessCategory);
        }
    }

    getBusinessFunction(businessCategory) {
        if (this.businessCategory === '') {
            return true;
        } else {
            return businessCategory === this.businessCategory;
        }
    }

}
