import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UtilsService} from '../_services/utils.service';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
    utils: any;

    constructor(public router: Router, private utilsService: UtilsService) {
        this.utils = utilsService;
    }

    ngOnInit(): void {
    }

    navigateToPage(path){
        this.router.navigate([path])
            .then(() => {
                window.location.reload();
            });
    }

}
