import {Component, OnDestroy, OnInit} from '@angular/core';
import {ThemeManagerService} from '../_services/theme-manager.service';
import {LayoutService} from '../_services/layout.service';
import {Router} from '@angular/router';
import {UtilsService} from '../_services/utils.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
    theme: ThemeManagerService;
    layout: LayoutService;
    currentTheme: string;
    subscription: any;

    constructor(theme: ThemeManagerService, layout: LayoutService, private router: Router,
                private utilsService: UtilsService
    ) {
        this.theme = theme;
        this.layout = layout;
    }

    ngOnInit() {
        this.subscription = this.theme.getThemeChangeEmitter()
            .subscribe(item => this.currentTheme = item);
        this.theme.setTheme('light');
        this.layout.toggleSideMenu();
        this.currentTheme = this.theme.getTheme();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
