import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../_services/utils.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlService } from '../_services/base-url.service';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.scss'],
})
export class EventSearchComponent implements OnInit {
  util: any;
  rowData: any = [];
  eventData: any =[];
  rowDataCopy: any = [];
  token: any;
  searchTable: any = [];
  eventDropDown: any = [];
  data: any;
  selectedValue: 'null';
  eventSearchObj: any[];
  noRowsTemplate = '';
  eventDataCopy: any;

  gridOptions: any = {
    enableBrowserTooltips: true,
    columnDefs: [
      {
        headerName: 'API FIELD NAME',
        field: 'apiFieldName',
        sortable: true,
        filter: true,
        tooltipField: 'apiFieldName',
      },
      {
        headerName: 'BUSINESS NAME',
        field: 'businessName',
        sortable: true,
        filter: true,
        tooltipField: 'businessName',
      },
      {
        headerName: 'DESCRIPTION',
        field: 'description',
        sortable: true,
        filter: true,
        tooltipField: 'description',
      },
      {
        headerName: 'EVENT TYPE',
        field: 'eventType',
        sortable: true,
        filter: true,
        tooltipField: 'eventType',
      },
      {
        headerName: 'DATA LENGTH',
        field: 'dataLength',
        sortable: true,
        filter: true,
        tooltipField: 'dataLength',
      },
      {
        headerName: 'COPYBOOK FIELD',
        field: 'copyBookFieldName',
        sortable: true,
        filter: true,
        tooltipField: 'copyBookFieldName',
      },
      {
        headerName: 'COPYBOOK',
        field: 'copyBooks',
        sortable: true,
        filter: true,
        tooltipField: 'copyBooks',
      },
      {
        headerName: 'SNOWFLAKE LOCATION',
        field: 'snowflakeLocation',
        sortable: true,
        filter: true,
        tooltipField: 'snowflakeLocation',
      },
      {
        headerName: 'TRIGGER EVENT',
        field: 'triggerEvent',
        sortable: true,
        filter: true,
        tooltipField: 'triggerEvent',
      },
      {
        headerName: 'TRIGGER VALUES',
        field: 'triggerValues',
        sortable: true,
        filter: true,
        tooltipField: 'triggerValues',
      },
    ],
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
    },

    onGridReady: (params) => {
      this.fetchRowData();
      params.api.setRowData(this.rowData);
    },
    overlayNoRowsTemplate: '<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"><span class="sr-only">Loading...</span></div>',
    overlayLoadingTemplate: '<span>No Records to Show</span>',
  };

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private http: HttpClient,
    private baseUrlService: BaseUrlService
  ) {
    this.util = utilsService;
  }

  ngOnInit(): void {
    this.utilsService.updateMetaValues();
  }


  fetchRowData() {
    this.token = this.baseUrlService.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.token,
      }),
    };

    const body = {};
    this.http
      .post(
        this.baseUrlService.getBaseURL() + '/api/fs/devportal/v1/allPubsubData',
        body,
        httpOptions
      )
      .subscribe(
        (data) => {
          this.searchTable = data;
          this.searchTable = this.searchTable.results;
          if (this.gridOptions.api && this.searchTable === undefined) {
            this.gridOptions.api.showLoadingOverlay();
          } else if (this.searchTable !== undefined) {
            for (const item of this.searchTable) {
              this.rowData.push(item);
              this.eventData.push(item.eventType);
            }
            this.eventDataCopy = new Set(this.eventData);
            this.rowDataCopy = [...this.rowData];
            this.renderInChunks();
          }
        },
        (error) => {
          if (error.status != 200) {
            this.gridOptions.api.showLoadingOverlay();
          }
        }
      );
  }

  onBtExport() {
    var params = {
      processCellCallback: this.processCells,
    };
    this.gridOptions.api.exportDataAsExcel(params);
  }

  processCells(cellParams: any) {
    if (
      cellParams &&
      cellParams.column.colId === 'dataHub' &&
      cellParams.value !== undefined
    ) {
      return cellParams.value.toLowerCase();
    } else return cellParams.value;
  }

  onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  resetAllFilters() {
    this.gridOptions.api.setFilterModel(null);
    (document.getElementById('filter-text-box') as HTMLInputElement).value = '';
    this.onFilterTextBoxChanged();
  }

  renderInChunks() {
    const DEFAULT_CHUNK_SIZE = 100;
    const dataLength = this.rowData.length;
    let results = [].concat(this.rowData);
    const nextRender = (howMany) => {
      var chunkSize = howMany || DEFAULT_CHUNK_SIZE;
      this.gridOptions.api.applyTransaction({
        add: results.slice(0, howMany),
      });
      results.splice(0, chunkSize);
      if (results.length) {
        const howMany =
          results.length > DEFAULT_CHUNK_SIZE
            ? DEFAULT_CHUNK_SIZE
            : results.length;
        setTimeout(() => {
          nextRender(howMany);
        }),
          10;
      }
    };
    if (dataLength > DEFAULT_CHUNK_SIZE) {
      nextRender(DEFAULT_CHUNK_SIZE);
    } else {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
  eventChange() {
    const columns = this.gridOptions.columnApi.getAllColumns();
    const valueColumn = columns.filter(
      (column) => column.getColDef().headerName === 'Event'
    )[0];
    this.gridOptions.columnApi.setColumnVisible(
      valueColumn,
      this.selectedValue === undefined
    );
    this.rowData =
      this.selectedValue !== undefined
        ? this.rowDataCopy.filter((x) => x.eventType === this.selectedValue)
        : this.rowDataCopy;
        if(this.rowData.length === 0){
          this.noRowsTemplate =
          `<span>No rows to show</span>`;
        }
  } 
}
