<div class="d-flex" id="wrapper">

    <!-- Page Content -->
    <div id="page-content-wrapper">

        <div id="main_container" class="container-fluid page-container">
            <div id="header-section">
                <div class='header-section__intro-section'>
                    <div class="col-md-auto">
                        <p id="header-section__intro-title">For the best performance</p>
                    </div>
                    <div>
                        <p id="header-section__intro-text">Please use either Google Chrome or Mozilla Firefox.
                        </p>
                    </div>
                </div>
            </div>

            <div class="download-browser-section row pt-4">
                <div id="download-chrome-section" class="col-md-12 text-center">
                    <a href="https://www.google.com/chrome/" class="btn btn-primary">Download Chrome</a>
                &nbsp;&nbsp;
                    <a href="https://www.mozilla.org/en-US/firefox/campaign/" class="btn btn-primary">Download
                        Firefox</a>
                </div>
            </div>

        </div>

    </div>
    <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->
