<footer>
    <div class="container" style="max-width:90%;margin-top:3em;">
        <div class="row">
            <div class="col-md-3">
                <div class="align-items-start flex-grow-1">
                    <a target="_blank" href="https://fiserv.com">
                        <img alt="fiserv footer logo" src="/portal/assets/images/fiserv_footer_logo.svg" class="footer-logo">
                    </a>
                    <p class="footer-subtitle">© 2023 Fiserv, Inc. or its affiliates.</p>
                </div>
            </div>
            <div class="col-md-3 footer-linkitem">
                <h5 class="mb-2">Who we serve</h5>
                <a (click)="this.utils.navigateToPage('/merchants')">Merchants</a>
                <a (click)="this.utils.navigateToPage('/financial-institutions')">Financial Institutions</a>
            </div>
            <div class="col-md-3 footer-linkitem">
                <h5 class="mb-2">Resources</h5>
                <a (click)="this.utils.navigateToPage('/apis')">Catalog</a>
                <a (click)="this.utils.navigateToPage('/search')">
                    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" id="search-icon" class="mb-1 mr-2">
                        <path d="M13.213 12.486l-4.058-4.09c.63-.822.97-1.825.97-2.88 0-1.264-.49-2.449-1.373-3.342A4.634 4.634 0 005.437.789a4.64 4.64 0 00-3.314 1.385A4.708 4.708 0 00.75 5.515c0 1.262.49 2.45 1.373 3.342a4.631 4.631 0 003.314 1.385c1.047 0 2.041-.344 2.855-.977l4.058 4.09a.128.128 0 00.181 0l.681-.686a.13.13 0 000-.183zm-5.3-4.475a3.467 3.467 0 01-2.476 1.033 3.467 3.467 0 01-2.475-1.033 3.525 3.525 0 01-1.025-2.496c0-.942.365-1.829 1.025-2.495a3.467 3.467 0 012.475-1.034c.935 0 1.815.366 2.475 1.034a3.525 3.525 0 011.026 2.495c0 .942-.365 1.83-1.026 2.496z" fill="#262626"></path>
                    </svg>Search</a>
                <a (click)="this.utils.navigateToPage('/support')">Support</a>
            </div>
            <div class="col-md-3">
                <div class="align-items-start flex-grow-1">
                    <a rel="noopener noreferrer" target="_blank" href="https://www.developerweek.com/awards/#winners">
                        <img src="/portal/assets/images/devies.png" alt="webby" width="97" height="131" class="cursor-pointer">
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>