<div class="container-fluid pt-3">
    <ul class="breadcrumb bg-white fiserv-orange-color">
        <li class="fiservBreadcrumbs"><a (click)="this.utilService.navigateToPage('')">Home</a></li>
        <li class="fiservBreadcrumbs">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
            <a (click)="this.utilService.navigateToPage('/financial-institutions')">Financial Institutions</a>
        </li>
        <li class="fiservBreadcrumbs">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
            <a (click)="this.utilService.navigateToPage('/product/IssuerSolutions')">Issuer Solutions</a></li>
        <li class="color-grey-disable fiservBreadcrumbs">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="breadcrumbsArrow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
            <span>API Search</span>
        </li>
    </ul>
    <h4 class="col col-sm-9 left-aligned" style="text-align: left">API Search</h4>
    <form #searchForm="ngForm" class="left-aligned">
        <div class="row mt-3 mb-5">
            <div class="col col-sm-5 pr-1">
                <select class="form-control" name="model.searchType" [(ngModel)]="model.searchType"
                        (ngModelChange)="placeHolderChange()">
                    <option value="API">RESTful Web Services (API) Service Names</option>
                    <option value="ODS_TRAN">Open Data Streams (ODS) Transaction Names</option>
                    <option value="X3270_FUNC">3270 Screen/Function Names</option>
                    <option value="CATEGORY">RESTful Web Services (API) Category Names</option>
                    <option value="WILDCARD">Comprehensive General Purpose Names</option>
                </select>
            </div>
            <div class="col col-sm-4 pl-1">
                <input class="form-control" type="text" id="filter-text-box" placeholder="{{model.somePlaceholder}}"
                       name="model.inputName" [(ngModel)]="model.inputName" [ngbTypeahead]="search" (selectItem)="selectedItem($event)"
                       required/>
            </div>
            <div class="col col-sm-3">
                <button id="searchBtn" class="btn btn-primary mr-2" (click)="submit($event)">
                   Search
                </button>
                <button class="btn btn-warning" (click)="resetAllFilters()">Clear all Filters</button>
            </div>
        </div>
    </form>
    <form *ngIf="showConfiguration" #clientForm="ngForm">
        <div class="row mt-3 mb-5">
            <div class="col col-sm-12">
                <ag-grid-angular
                        style="width: 100%; height: 500px; margin-bottom: 50px; margin-top: 50px"
                        class="ag-theme-alpine cursor-pointer"
                        [rowData]="rowData"
                        [gridOptions]="gridOptions"
                >
                </ag-grid-angular>
            </div>
        </div>
    </form>
    <div class="mt-5"></div>
</div>
