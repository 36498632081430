import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home';
import {HttpClientModule} from '@angular/common/http';
import {ThemeManagerService} from './_services/theme-manager.service';
import {FooterComponent} from './footer/footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavbarComponent} from './navbar/navbar.component';
import {ApiSearchComponent} from './api-search/api-search.component';
import {RouterModule} from '@angular/router';
import {DatePipe} from '@angular/common';
import {ToastsContainerComponent} from './toasts-container/toasts-container.component';
import {SearchPipe} from './_pipe/search.pipe';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ApiSearchDetailsComponent} from './api-search-details/api-search-details.component';
import {SwaggerUiTestComponent} from './swagger-ui-test/swagger-ui-test.component';
import {AgGridModule} from 'ag-grid-angular';
import {SplashComponent} from './splash/splash.component';
import {NAapibrowserComponent} from './NA/naapibrowser/naapibrowser.component';
import {NAjourneyComponent} from './NA/najourney/najourney.component';
import {EMEAapibrowserComponent} from './EMEA/emeaapibrowser/emeaapibrowser.component';
import {EMEAjourneyComponent} from './EMEA/emeajourney/emeajourney.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {SearchComponent} from './search/search.component';
import {SearchResultsComponent} from './search-results/search-results.component';
import {BrowserSupportComponent} from './browser-support/browser-support.component';
import {FileSearchComponent} from "./file-search/file-search.component";
import {FileSearchDetailsComponent} from "./file-search-details/file-search-details.component";
import {FileSearchDetailsTreeComponent} from "./file-search-details-tree/file-search-details-tree.component";
import {EventSearchComponent} from "./event-search/event-search.component";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        FooterComponent,
        NavbarComponent,
        ApiSearchComponent,
        ToastsContainerComponent,
        SearchPipe,
        ApiSearchDetailsComponent,
        SwaggerUiTestComponent,
        SplashComponent,
        NAapibrowserComponent,
        NAjourneyComponent,
        EMEAapibrowserComponent,
        EMEAjourneyComponent,
        ToolbarComponent,
        SearchComponent,
        SearchResultsComponent,
        BrowserSupportComponent,
        FileSearchComponent,
        FileSearchDetailsComponent,
        FileSearchDetailsTreeComponent,
        EventSearchComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        RouterModule,
        NgMultiSelectDropDownModule,
        AgGridModule.withComponents([])
    ],
    providers: [ThemeManagerService, DatePipe, SearchPipe],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule {
}
