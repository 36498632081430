import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home';
import {NAapibrowserComponent} from "./NA/naapibrowser/naapibrowser.component";
import {NAjourneyComponent} from "./NA/najourney/najourney.component";
import {EMEAapibrowserComponent} from "./EMEA/emeaapibrowser/emeaapibrowser.component";
import {EMEAjourneyComponent} from "./EMEA/emeajourney/emeajourney.component";
import {SearchComponent} from "./search/search.component";
import {SearchResultsComponent} from "./search-results/search-results.component";
import {BrowserSupportComponent} from "./browser-support/browser-support.component";
import {FileSearchComponent} from "./file-search/file-search.component";
import {FileSearchDetailsComponent} from "./file-search-details/file-search-details.component";
import {FileSearchDetailsTreeComponent} from "./file-search-details-tree/file-search-details-tree.component";
import {EventSearchComponent} from "./event-search/event-search.component";
const routes: Routes = [
  {
    path: 'unsupported-browser',
    component: BrowserSupportComponent
  },
  {
    path: 'portal',
    component: HomeComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'NA/apibrowser',
    component: NAapibrowserComponent
  },
  {
    path: 'Global/apibrowser',
    component: EMEAapibrowserComponent
  },
  {
    path: 'NA/journey',
    component: NAjourneyComponent
  },
  {
    path: 'Global/journey',
    component: EMEAjourneyComponent
  },
  {
    path: 'NA/search',
    component: SearchComponent
  },
  {
    path: 'NA/search-results',
    component: SearchResultsComponent
  },
  {
    path: 'NA/file-search',
    component: FileSearchComponent
  },
  {
    path: 'NA/file-search-details',
    component: FileSearchDetailsComponent
  },
  {
    path: 'NA/file-search-details-tree',
    component: FileSearchDetailsTreeComponent
  },
  {
    path: 'NA/event-search',
    component: EventSearchComponent
  },
  {path: '', redirectTo: 'portal', pathMatch: 'full'},
  // otherwise redirect to home
  {path: '**', redirectTo: 'portal'}
];

export const AppRoutingModule = RouterModule.forRoot(routes);
