<nav class="navbar navbar-expand-lg navbar-{{currentTheme}} bg-{{currentTheme}} border-bottom">

  <a class="navbar-brand" [routerLink]="['/home']">
    <div class="fiserv-logo-small">
      <img src="/portal/assets/images/fiserv-logo-small.png" alt="Fiserv Logo" class="fiserv-logo-small" />
    </div>
  </a>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          Product
        </a>
        <div class="dropdown-menu" aria-labelledby="productDropdown">
          <a class="dropdown-item" href="#">API Catalog</a>
          <a class="dropdown-item" href="#">Use Cases</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="developerDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          Developer
        </a>
        <div class="dropdown-menu" aria-labelledby="developerDropdown">
          <a class="dropdown-item" href="#">Documentation</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="supportDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          Support
        </a>
        <div class="dropdown-menu" aria-labelledby="supportDropdown">
          <a class="dropdown-item" href="#">FAQ</a>
          <a class="dropdown-item" href="#">RESTful quick-start guide</a>
          <a class="dropdown-item" href="#">Make your first API call</a>
          <a class="dropdown-item" href="#">Contact Us</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="regionDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          Region
        </a>
        <div class="dropdown-menu" aria-labelledby="regionDropdown">
          <a class="dropdown-item" href="#">Global</a>
          <a class="dropdown-item" href="#">North America</a>
        </div>
      </li>
    </ul>

    <!--<form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
    </form>-->
  </div>
</nav>
