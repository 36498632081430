<div class="main_container">
    <div id="api-browser-header-section">

        <app-toolbar></app-toolbar>

        <div class="api-browser-header-section__intro-title-section">
            <div>
                <p id="api-browser-header-section__intro-title"> Our services, directly into your hands</p>
            </div>
            <div>
                <p id="api-browser-header-section__intro-text">Browse our broad selection of Financial Service solution
                    categories
                </p>
            </div>
        </div>
    </div>
    <div class="api-browser-content-container">
        <div class="api-browser-grid-container" align="center">
            <div class="row">
                <div class="col-md-3 api-browser__solution-category">
                    <div id="api-browser__solution-category-filter-section" class="accordion-panel">
                        <div class="container" align="center">
                            <p id="api-browser__solution-category-filter-title">Solution Category</p>
                            <p id="api-browser__solution-category-filter-text">Filter by</p>
                            <div class="api-browser_filters">

                                <div *ngFor="let item of mappingsData.default | keyvalue: originalOrder">
                                    <div *ngIf="item.value.unified_category !== ''">
                                        <label class="api-browser__solution-category-filters">{{item.value.unified_category}}
                                            <input type="checkbox" name="Categories" value="{{item.value.filter}}"
                                                   (change)="filterSelection()" alt="{{item.value.filter}}"
                                                   [checked]="item.value.checked" [(ngModel)]="item.value.checked">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <button class="api-browser__clear-filters" (click)="showAll()">Clear all</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="api-browser__tile-section" class="col-md-9">
                    <div id="api-browser__function-filter-section">
                        <button id="credit-button"
                                class="api-browser__function-filter credit-toggle {{businessCategory === 'Credit' ? 'blur' : ''}}"
                                value="Credit"
                                (click)="businessFunction('Credit')" business-functionFilter="Credit">CREDIT
                        </button>
                        <button id="debit-button"
                                class="api-browser__function-filter debit-toggle {{businessCategory === 'Debit' ? 'blur' : ''}}"
                                value="Debit"
                                (click)="businessFunction('Debit')" business-functionFilter="Debit">DEBIT
                        </button>
                        <button id="prepaid-button"
                                class="api-browser__function-filter prepaid-toggle {{businessCategory === 'Prepaid' ? 'blur' : ''}}"
                                value="Prepaid"
                                (click)="businessFunction('Prepaid')" business-functionFilter="Prepaid">PREPAID
                        </button>
                    </div>

                    <div *ngFor="let item of mappingsData.default | keyvalue">
                        <div *ngIf="item.value.unified_category !== ''">
                            <div *ngIf="showAllSections || item.value.checked"
                                 class="row api-browser__unified-section filterCat All {{item.value.filter}}"
                                 data-toggle="collapse" data-target="#collapsible_{{item.value.filter}}">
                                <div class="col-12 api-browser__category-header-section">
                                    <p class="api-browser__unified-category-header">{{item.value.unified_category}}
                                        <span class="collapsible-arrow">
                                            <i id="toggle-icon-{{item.value.filter}}"
                                               class="fas collapsible-icons fa-angle-up"
                                               (click)="toggleClass($event, item.value.filter)"> </i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="collapse show row" id="collapsible_{{item.value.filter}}">
                                <div class="row {{item.value.unified_category}}row">
                                    <div *ngFor="let tag of item.value.tags | keyvalue">


                                        <div id="api-browser-cards"
                                             class="filterCat All {{item.value.filter}} {{item.value.filter}}-FI_function"
                                             header-type="{{item.value.filter}}-header"
                                             business-function="{{tag.value.function}}"
                                             *ngIf="(showAllSections || item.value.checked) && getBusinessFunction(tag.value.function)">
                                            <div class="card" id="api-browser__tiles">
                                                <div class="card-body" id="tile-body">
                                                    <h5 class="card-title">{{tag.value.display}}</h5>
                                                    <h6 class="card-subtitle mb-2"
                                                        id="api-browser__card-business-type">{{tag.value.function}}</h6>
                                                </div>
                                                <div id="links">
                                                    <a class="card-link"
                                                       href="{{isQAOrLocal() ? tag.value.QA_apiLink : tag.value.PROD_apiLink}}">
                                                        View APIs </a>
                                                    <a class="card-link"
                                                       href="https://docs.firstdata.com/org/global/docs/user-manuals">
                                                        Download </a>
                                                    <a *ngIf="tag.value.examples !== ''" class="card-link"
                                                       href="{{tag.value.examples}}"> Examples </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
