import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BaseUrlService} from './base-url.service';
import {Router} from '@angular/router';
import {ToastService} from './toast.service';
import {ThemeManagerService} from './theme-manager.service';
import { Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    data: any;
    environment: string = window.location.href;

    constructor(private http: HttpClient,
                private baseUrlService: BaseUrlService,
                private router: Router,
                private toastService: ToastService,
                private theme: ThemeManagerService,
                private meta: Meta
    ) {
    }

    startLoadingEmitter: EventEmitter<string> = new EventEmitter();
    doneLoadingEmitter: EventEmitter<string> = new EventEmitter();
    responseEmitter: EventEmitter<string> = new EventEmitter();
    public RESPONSE_TYPE = {
        FIND: {
            command: 'find',
            url: this.baseUrlService.getBaseURL() + '/api/fs/adminui/v1/findConfig'
        },
        UPDATE: {
            command: 'update',
            url: this.baseUrlService.getBaseURL() + '/api/fs/adminui/v1/createConfig'
        },
        VALIDATE: {
            command: 'validate',
            url: this.baseUrlService.getBaseURL() + '/api/fs/adminui/v1/validateConfig'
        },
        STATUS: {
            command: 'status',
            url: this.baseUrlService.getBaseURL() + '/api/fs/httpsproxy/v1/status'
        },
        REFRESH_CACHE: {
            command: 'refreshCache',
            url: this.baseUrlService.getBaseURL() + '/api/fs/httpsproxy/v1/refreshcache'
        },
        INIT_ENTITLEMENTS_LIST: {
            command: 'initResponseEntitlements',
            url: this.baseUrlService.getBaseURL() + '/api/fs/adminui/v1/findConfig'
        },
        INIT_SIDEBAR: {
            command: 'initResponseSidebar',
            url: this.baseUrlService.getBaseURL() + '/api/fs/httpsproxy/v1/menu?common.app=admin'
        },
        ONDOT_RETRIEVE_AUDIT: {
            command: 'ondotRetrieveAudit',
            url: this.baseUrlService.getBaseURL() + '/api/fs/adminui/v1/retrieveAudit'
        }
    };

    successResponse(data, resType, successCallback?) {
        this.doneLoadingEmitter.emit('doneLoading');
        this.responseEmitter.emit(JSON.stringify({
            success: true,
            responseType: resType,
            response: data,
            successCallback
        }));
    }

    errorResponse(error, errorCallback?) {
        this.doneLoadingEmitter.emit('doneLoading');
        this.showResponseErrorMessage(error);
        this.responseEmitter.emit(JSON.stringify({
            success: false,
            errorCallback
        }));
    }

    getRequest(url, headers, resType, successCallback?, errorCallback?) {
        this.startLoadingEmitter.emit('startLoading');
        this.http.get(url, headers)
            .subscribe(
                (data: any) => {
                    this.successResponse(data, resType, successCallback);
                },
                error => {
                    this.errorResponse(error, errorCallback);
                }
            );
    }

    postRequest(url, httpOptions, body, resType, successCallback?, errorCallback?) {
        this.startLoadingEmitter.emit('startLoading');
        this.http.post(url, body, httpOptions)
            .subscribe(
                (data: any) => {
                    this.successResponse(data, resType, successCallback);
                },
                error => {
                    this.errorResponse(error, errorCallback);
                }
            );
    }

    isValidString(s: string) {
        let isValid = true;
        if (s === '') {
            isValid = false;
        }
        if (s === undefined) {
            isValid = false;
        }
        if (s === null) {
            isValid = false;
        }
        return isValid;
    }

    isAtLeastOneStringDefined(s1: string, s2: string) {
        return this.isValidString(s1) || this.isValidString(s2);
    }

    isAtLeastStringDefined(s1: string, s2: string, s3: string, s4: string) {
        return this.isValidString(s1) || this.isValidString(s2) || this.isValidString(s3) || this.isValidString(s4);
    }

    isTypeFormatValid(typeFormat: string) {
        let isValid = true;
        if (typeFormat.indexOf('primary') === -1 && typeFormat.indexOf('secondary') === -1 && typeFormat.indexOf('tertiary') === -1) {
            isValid = false;
        }
        const c = typeFormat.charAt(typeFormat.length - 1);
        if (isNaN(c as any) && c !== '*') {
            isValid = false;
        }

        return isValid;
    }

    showResponseErrorMessage(error) {
        const err = this.getErrorMessage(error);
        this.showMessageError(err);
        return err;
    }

    getErrorMessage(error) {
        let err = error.message;
        if (error.hasOwnProperty('error')) {
            err = error.error.message;
        }
        if (!this.isValidString(err)) {
            err = 'An unspecified error has occurred.';
        }
        if (err.indexOf('Connection refused') !== -1) {
            this.startLoadingEmitter.emit('startLoading');
            err = err + '. Please log out from the application and log back in to keep using the Admin Tool.';
        }
        return err;
    }

    showMessageError(msg, doNotReauth?) {
        this.showMessage(msg, 'bg-danger text-light', 10000, doNotReauth);
    }

    showMessageSuccess(msg, doNotReauth?) {
        this.showMessage(msg, 'bg-success text-light', 10000, doNotReauth);
    }

    showMessage(msg, css, delayMs, doNotReauth?) {
        if (doNotReauth === undefined) {
            doNotReauth = true;
        }
        this.toastService.show(msg, {classname: css, delay: delayMs});
        if (doNotReauth) {
            return;
        }
    }

    navigateToPage(path){
        const apiArr = path.split('/');
        const category = apiArr[5]
        const apiName = apiArr[7]
        if (category == "paypoint") {
            path =  "/product/IssuerSolutions/api/?type=post&path=/" + apiName;
        }
        if (this.environment.indexOf('http://localhost') > -1) {
            window.location.replace('https://dev-developerstudio.fiserv.com' + path);
        }
        else if (this.environment.indexOf('https://qa-fiapi.firstdata.com') > -1) {
            window.location.replace('https://qa-developer.fiserv.com' + path);
        }
        else if (this.environment.indexOf('https://cat-fiapi.firstdata.com') > -1) {
            window.location.replace('https://stage-developer.fiserv.com' + path);
        }
        else if (this.environment.indexOf('https://fiapi.firstdata.com') > -1) {
            window.location.replace('https://developer.fiserv.com' + path);
        }
        else {
        }
    }

    updateMetaValues() {
        const env = this.baseUrlService.getEnvironment()

        if(env === 'LOCAL' ) {
            //For local purposes, avoid cross origin error *
            this.meta.updateTag({
                httpEquiv: 'Content-Security-Policy',
                content: 'default-src *; style-src \'self\' \'unsafe-inline\'; script-src \'self\' \'unsafe-inline\' \'unsafe-eval\''
            });
        } else {
            this.meta.updateTag({
                httpEquiv: 'Content-Security-Policy',
                content: 'default-src \'self\' \'unsafe-inline\'; frame-src \'self\' ;img-src \'self\' data: ; font-src \'self\' data:'
            });
        }
    }
}
